import './App.css';
import CampaignForm from './components/CampaignForm';

function App() {
  return (
    <div className="App">
      <CampaignForm/>
    </div>
  );
}

export default App;
